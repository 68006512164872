/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'settings-outline': {
    width: 17,
    height: 17,
    viewBox: '0 0 17 17',
    data: '<path pid="0" d="M14.995 9.324l1.843 1.41c.166.128.21.358.105.545l-1.747 2.94a.445.445 0 01-.534.188l-2.175-.859c-.455.34-.927.62-1.477.842l-.323 2.252a.438.438 0 01-.437.357H6.754a.438.438 0 01-.437-.357l-.323-2.252a6.11 6.11 0 01-1.477-.842l-2.176.859a.445.445 0 01-.533-.187L.061 11.279a.412.412 0 01.104-.544L2.01 9.324l-.06-.825.06-.85L.165 6.264a.412.412 0 01-.104-.544l1.747-2.941c.105-.187.341-.264.533-.187l2.176.85c.455-.332.926-.621 1.477-.833L6.317.356a.438.438 0 01.437-.357h3.496c.218 0 .402.153.437.357l.323 2.253a6.38 6.38 0 011.477.833l2.175-.85a.435.435 0 01.534.187l1.747 2.94a.421.421 0 01-.105.545l-1.843 1.385.06.85-.06.825zm-11.3-.825c0 .493.08.96.22 1.411l-1.81 1.445.656 1.105 2.202-.799a4.821 4.821 0 002.49 1.403l.385 2.235h1.31l.385-2.235a4.835 4.835 0 002.508-1.394l2.193.799.656-1.105-1.81-1.445a4.52 4.52 0 00.228-1.42c0-.484-.079-.96-.218-1.402l1.8-1.437-.656-1.105-2.184.79a4.86 4.86 0 00-2.508-1.41l-.385-2.236h-1.31l-.385 2.236a4.86 4.86 0 00-2.508 1.41L2.77 4.548l-.656 1.105 1.8 1.445c-.14.442-.218.918-.218 1.402zm4.807-2.975c.811 0 1.589.313 2.163.871.573.558.895 1.315.895 2.104 0 .789-.322 1.546-.895 2.104a3.102 3.102 0 01-2.163.871c-.811 0-1.59-.313-2.163-.871a2.934 2.934 0 01-.896-2.104c0-.789.323-1.546.896-2.104a3.103 3.103 0 012.163-.871zm0 1.7a1.33 1.33 0 00-.927.373c-.246.24-.384.564-.384.902 0 .338.138.662.384.902.246.239.58.373.927.373.348 0 .681-.134.927-.373.246-.24.384-.564.384-.902 0-.338-.138-.662-.384-.902a1.33 1.33 0 00-.927-.373z" _fill="#898B9B"/>'
  }
})
